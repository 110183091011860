import React, { useState } from 'react';

type Box = {
    id: string;
    name?: string;
    ownerId?: string;
    sharedWith?: string[];
    task?: string | { name: string; url?: string };
    firmwareVersion?: string;
};

type CotextData = {
    boxes?: Box[];
};

type ContextValue = {
    updateData: (dataToMerge: Partial<CotextData>) => CotextData;
    data: CotextData;
};

export const SharedDataContext = React.createContext<ContextValue>({
    data: {},
    updateData: (dataToMerge: Partial<CotextData>) => {
        return {} as any as CotextData;
    },
});

export const SharedDataProvider: React.FunctionComponent = (props) => {
    const [data, setData] = useState<CotextData>({});

    const updateData = (dataToMerge: any) => {
        const newData = {
            ...data,
            ...dataToMerge,
        };
        setData(newData);
        return newData;
    };

    return (
        <SharedDataContext.Provider
            value={{
                data,
                updateData,
            }}
        >
            {props.children}
        </SharedDataContext.Provider>
    );
};

import axios from 'axios';

export async function fetchApi({
    path,
    params,
    idToken,
    method = 'POST',
    isFormData = false,
}: {
    path: string;
    params: any;
    idToken: string;
    method?: 'GET' | 'POST';
    isFormData?: boolean;
}) {
    let json: any = null;
    let error = null;

    try {
        if (!idToken) {
            console.error(`token is empty for ${path} request`);
            throw new Error('token is empt');
        }
        // const response = await fetch(
        //     `https://superbest-memebox.com/api/${path}`,
        //     {
        //         method,
        //         mode: 'cors', // no-cors, *cors, same-origin
        //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //         credentials: 'omit', // 'same-origin', // include, *same-origin, omit
        //         headers: {
        //             'Content-Type': 'application/json',
        //             // 'Content-Type': 'application/x-www-form-urlencoded',
        //             Authorizaton: idToken || '',
        //         },
        //         redirect: 'follow', // manual, *follow, error
        //         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //         body: method === 'POST' ? JSON.stringify(params) : undefined, // body data type must match "Content-Type" header
        //     }
        // );
        // json = await response.json();

        const response = await axios.request({
            method,
            url: `https://superbest-memebox.com/api/${path}`,
            data: method === 'POST' ? params : undefined,
            responseType: 'json',
            headers: {
                'Content-Type': isFormData
                    ? 'multipart/form-data'
                    : 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: idToken || '',
            },
        });
        json = response.data;
    } catch (e) {
        error = e;
    }

    return {
        error,
        json,
    };
}

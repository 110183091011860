import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import logoImage from './logo.png';

import { AuthContext } from '../contexts/authContext';
import { getSession } from '../libs/cognito';
import { SharedDataContext } from '../dataContext';
import { fetchApi } from '../fetchApi';
import BoxActions from '../components/boxActions';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        textAlign: 'center',
    },
    session: {
        width: '80vw',
        overflow: 'auto',
        overflowWrap: 'break-word',
        fontSize: '16px',
    },
    hero: {
        width: '100%',
        background: 'rgb(220,220,220)',
    },
}));

export default function Home() {
    const classes = useStyles();

    const history = useHistory();

    const auth = useContext(AuthContext);
    const sharedData = useContext(SharedDataContext);

    function signOutClicked() {
        auth.signOut();
        history.push('/');
    }

    function changePasswordClicked() {
        history.push('changepassword');
    }

    const m = window.location.hash.match(/\#\/shared\/([^\/]+)/);
    let sharedBoxIdToAdd = (m && m[1]) || null;

    const m1 = window.location.hash.match(/\#\/add\/([^\/]+)/);
    let boxIdToAdd = (m1 && m1[1]) || null;

    const [isAddingNewBox, setIsAddingNewBox] = useState(false);
    const [addBoxError, setAddBoxError] = useState('');
    const addNewBox = useCallback(async () => {
        setIsAddingNewBox(true);
        setAddBoxError('');
        const result = await fetchApi({
            path: 'addBox',
            params: {
                boxId: boxIdToAdd || sharedBoxIdToAdd,
                isShared: !!sharedBoxIdToAdd,
            },
            idToken: auth.sessionInfo?.idToken || '',
        });

        if (!result.error && result.json?.ok) {
            window.location.href = window.location.origin;
        } else {
            setAddBoxError('Error');
            setIsAddingNewBox(false);
        }
    }, [boxIdToAdd, sharedBoxIdToAdd, auth.sessionInfo?.idToken]);

    // request list of boxes at componentDidMount
    const [isLoadingBoxes, setIsLoadingBoxes] = useState(false);
    const [listBoxesError, setListBoxesError] = useState('');
    const [hasFetchedBoxes, setHasFetchedBoxes] = useState(false);

    useEffect(() => {
        const loadBoxes = async () => {
            setIsLoadingBoxes(true);
            setListBoxesError('');

            const result: any = await fetchApi({
                path: 'listBoxes',
                method: 'POST',
                params: {},
                idToken: auth.sessionInfo?.idToken || '',
            });

            setHasFetchedBoxes(true);

            if (!result.error && result.json?.ok) {
                sharedData.updateData({ boxes: result.json.boxes.slice() });
            } else {
                setListBoxesError(result.error || 'error');
                setIsLoadingBoxes(false);
            }
        };
        if (!sharedData.data.boxes && !hasFetchedBoxes) {
            loadBoxes();
        }
    }, [auth.sessionInfo?.idToken, hasFetchedBoxes, sharedData]);

    const boxes = sharedData.data.boxes || [];

    return (
        <Grid container direction="column">
            {boxIdToAdd && auth.sessionInfo?.idToken ? (
                <Grid>
                    {addBoxError ? <Box>Something went wrong</Box> : null}
                    <Button
                        variant="contained"
                        onClick={addNewBox}
                        disabled={isAddingNewBox}
                    >
                        Add new memebox
                    </Button>
                </Grid>
            ) : null}

            {sharedBoxIdToAdd && auth.sessionInfo?.idToken ? (
                <Grid>
                    {addBoxError ? <Box>Something went wrong</Box> : null}
                    <Button
                        variant="contained"
                        onClick={addNewBox}
                        disabled={isAddingNewBox}
                    >
                        Add shared memebox
                    </Button>
                </Grid>
            ) : null}

            <Grid>
                <h2>Boxes:</h2>
                {boxes.map((box) => (
                    <Box key={box.id}>
                        <Link href={`#/box/${box.id}`}>{box.name}</Link>{' '}
                        {(box.task as any)?.name || box.task}{' '}
                        {/* {(box.task as any)?.url ? (
                            <a
                                href={(box.task as any).url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {(box.task as any).url}
                            </a>
                        ) : null} */}
                    </Box>
                ))}
                {listBoxesError ? (
                    <Box>
                        Error loading boxes: {JSON.stringify(listBoxesError)}
                    </Box>
                ) : null}
            </Grid>

            {boxes.length ? (
                <BoxActions
                    actionsText="Send actions to all"
                    imageActionText="Send image to all"
                    imageSendText="Send image to all"
                    boxIds={boxes.map((b) => b.id)}
                />
            ) : null}
            {/* <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
        <Box className={classes.hero} p={4}>
          <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
            <Box m={2}>
              <img src={logoImage} width={224} height={224} alt="logo" />
            </Box>
            <Box m={2}>
              <Link underline="none" color="inherit" href="https://github.com/dbroadhurst/aws-cognito-react">
                <Grid container direction="row" justify="center" alignItems="center">
                  <Box mr={3}>
                    <GitHubIcon fontSize="large" />
                  </Box>
                  <Typography className={classes.title} variant="h3">
                    AWS Cognito Starter Home
                  </Typography>
                </Grid>
              </Link>
            </Box>
            <Box m={2}>
              <Button onClick={signOutClicked} variant="contained" color="primary">
                Sign Out
              </Button>
            </Box>
            <Box m={2}>
              <Button onClick={changePasswordClicked} variant="contained" color="primary">
                Change Password
              </Button>
            </Box>
          </Grid>
        </Box>
        <Box m={2}>
          <Typography variant="h5">Session Info</Typography>
          <pre className={classes.session}>{JSON.stringify(auth.sessionInfo, null, 2)}</pre>
        </Box>
        <Box m={2}>
          <Typography variant="h5">User Attributes</Typography>
          <pre className={classes.session}>{JSON.stringify(auth.attrInfo, null, 2)}</pre>
        </Box>
        <Box m={2}>
          <Button onClick={}>Test api</Button>
        </Box>
      </Grid> */}
        </Grid>
    );
}

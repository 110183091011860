import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import IosShareIcon from '@mui/icons-material/IosShare';

import logoImage from './logo.png';

import { AuthContext } from '../contexts/authContext';
import { getSession } from '../libs/cognito';
import { SharedDataContext } from '../dataContext';
import { fetchApi } from '../fetchApi';
import BoxActions from '../components/boxActions';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        textAlign: 'center',
    },
    session: {
        width: '80vw',
        overflow: 'auto',
        overflowWrap: 'break-word',
        fontSize: '16px',
    },
    hero: {
        width: '100%',
        background: 'rgb(220,220,220)',
    },
}));

export default function BoxPage() {
    // const classes = useStyles();

    const sharedData = useContext(SharedDataContext);
    // const history = useHistory();
    const routeParams: any = useParams();
    const boxId = routeParams.boxId;
    const storedBox: any = (sharedData.data.boxes || []).find(
        (b: any) => b.id === boxId
    );

    const [hasFetchedBoxes, setHasFetchedBoxes] = useState(false);

    const auth = useContext(AuthContext);

    // download boxes if data is empty
    useEffect(() => {
        const loadBoxes = async () => {
            const result: any = await fetchApi({
                path: 'listBoxes',
                method: 'POST',
                params: {},
                idToken: auth.sessionInfo?.idToken || '',
            });

            setHasFetchedBoxes(true);
            if (!result.error && result.json?.ok) {
                sharedData.updateData({ boxes: result.json.boxes });
            }
        };
        if (boxId && !storedBox && !hasFetchedBoxes) {
            loadBoxes();
        }
    }, [
        boxId,
        hasFetchedBoxes,
        auth.sessionInfo?.idToken,
        sharedData,
        storedBox,
    ]);

    const shareLink = `${window.location.origin}/#/shared/${storedBox?.id}`;

    return (
        <Grid container direction="column">
            {storedBox && (
                <>
                    <Grid container alignItems="center">
                        <h2>Box {storedBox.name}</h2>{' '}
                        <Box marginLeft={4}>
                            <button
                                onClick={() => {
                                    navigator.clipboard.writeText(shareLink);
                                }}
                            >
                                <IosShareIcon />
                            </button>
                        </Box>
                    </Grid>

                    {/* <div>Firmware v{storedBox.firmwareVersion}</div> */}
                    <div>
                        Current action:{' '}
                        <b>
                            {(storedBox.task as any)?.name || storedBox.task}{' '}
                        </b>
                        {/* {(storedBox.task as any)?.url ? (
                            <a
                                href={(storedBox.task as any).url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {(storedBox.task as any).url}
                            </a>
                        ) : null} */}
                    </div>
                    <BoxActions
                        boxIds={[boxId]}
                        imageSendText={`Send to ${storedBox.name}`}
                    />
                </>
            )}
        </Grid>
    );
}

import React from 'react';
import './App.css';

import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import AuthProvider, {
    AuthIsSignedIn,
    AuthIsNotSignedIn,
} from './contexts/authContext';

import SignIn from './routes/auth/signIn';
import SignUp from './routes/auth/signUp';
import VerifyCode from './routes/auth/verify';
import RequestCode from './routes/auth/requestCode';
import ForgotPassword from './routes/auth/forgotPassword';
import ChangePassword from './routes/auth/changePassword';
import Landing from './routes/landing';
import Home from './routes/home';
import BoxPage from './routes/boxPage';
import Link from '@mui/material/Link';
import {
    Box,
    Container,
    Grid,
    adaptV4Theme,
    responsiveFontSizes,
    createTheme,
    ThemeProvider,
    CssBaseline,
} from '@mui/material';
import { SharedDataProvider } from './dataContext';

let lightTheme = createTheme(
    adaptV4Theme({
        palette: {
            mode: 'light',
        },
    })
);
lightTheme = responsiveFontSizes(lightTheme);

// let darkTheme = createMuiTheme({
//   palette: {
//     mode: 'dark',
//   },
// })
// darkTheme = responsiveFontSizes(darkTheme)

const SignInRoute: React.FunctionComponent = () => (
    <Router>
        <Switch>
            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/verify" component={VerifyCode} />
            <Route path="/requestcode" component={RequestCode} />
            <Route path="/forgotpassword" component={ForgotPassword} />

            <Route path="/" component={Landing} />
        </Switch>
    </Router>
);

const MainRoute: React.FunctionComponent = () => {
    return (
        <Router>
            <Container maxWidth="md">
                <Grid container direction="column">
                    <Box>
                        <Link href="#/">Home</Link>
                    </Box>
                    <SharedDataProvider>
                        <Box>
                            <Switch>
                                <Route
                                    path="/changepassword"
                                    component={ChangePassword}
                                />
                                <Route path="/box/:boxId" component={BoxPage} />
                                <Route path="/" component={Home} />
                            </Switch>
                        </Box>
                    </SharedDataProvider>
                </Grid>
            </Container>
        </Router>
    );
};

const App: React.FunctionComponent = () => (
    <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <AuthProvider>
            <AuthIsSignedIn>
                <MainRoute />
            </AuthIsSignedIn>
            <AuthIsNotSignedIn>
                <SignInRoute />
            </AuthIsNotSignedIn>
        </AuthProvider>
    </ThemeProvider>
);

export default App;

import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { debounce } from 'lodash';
import axios from 'axios';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from './react-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useHistory, useParams } from 'react-router-dom';

import makeStyles from '@mui/styles/makeStyles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';

import logoImage from './logo.png';

import { AuthContext } from '../contexts/authContext';
import { getSession } from '../libs/cognito';
import { fetchApi } from '../fetchApi';
import { SharedDataContext } from '../dataContext';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';

let previewUrl: string;

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        textAlign: 'center',
    },
    session: {
        width: '80vw',
        overflow: 'auto',
        overflowWrap: 'break-word',
        fontSize: '16px',
    },
    hero: {
        width: '100%',
        background: 'rgb(220,220,220)',
    },
}));

export default function BoxActions({
    boxIds,
    actionsText,
    imageActionText,
    imageSendText,
}: {
    boxIds: string[];
    actionsText?: string;
    imageActionText?: string;
    imageSendText?: string;
}) {
    const auth = useContext(AuthContext);
    const userId = auth.attrInfo.find(
        (attr: any) => attr.Name === 'sub'
    )?.Value;

    const sharedData = useContext(SharedDataContext);
    const isOwnersBox =
        userId &&
        boxIds.length === 1 &&
        sharedData.data.boxes?.find((b) => b.id === boxIds[0])?.ownerId ===
            userId;

    const [isSending, setIsSending] = useState(false);

    const imgRef = useRef<HTMLImageElement>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);

    const sendAction = useCallback(
        (action: string) => {
            const send = async () => {
                setIsSending(true);
                if (action === 'ota' && !isOwnersBox) {
                    return;
                }
                const result = await fetchApi({
                    path: 'addBoxTask',
                    idToken: auth.sessionInfo?.idToken || '',
                    method: 'POST',
                    params: {
                        boxIds,
                        task: action,
                    },
                });

                setIsSending(false);

                if (!result.error && result.json.ok && result.json.boxes) {
                    sharedData.updateData({ boxes: result.json.boxes });
                }
            };

            if (action === 'ota') {
                if (!window.confirm('Are you sure?')) {
                    return;
                }
            }

            if (!isSending) {
                send();
            }
        },
        [isSending, auth.sessionInfo?.idToken, boxIds, sharedData]
    );

    const [imageSrc, setImageSrc] = useState<
        string | ArrayBuffer | null | undefined
    >('');

    const [imageFile, setImageFile] = useState<File | null>(null);
    useEffect(() => {
        if (!imageFile) {
            return;
        }
        setCrop(undefined);
        const reader = new FileReader();
        reader.onload = (e) => {
            if (!e?.target?.result) {
                return;
            }
            setImageSrc(e.target.result.toString() || '');
        };
        reader.readAsDataURL(imageFile);
    }, [imageFile]);

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        // imgRef.current = e.currentTarget as HTMLImageElement;

        const { width, height } = e.currentTarget;

        // This is to demonstate how to make and center a % aspect crop
        // which is a bit trickier so we use some helper functions.
        const crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 70,
                },
                4 / 3,
                width,
                height
            ),
            width,
            height
        );

        setCrop(crop);
    };

    const updateCropPreview = useCallback(() => {
        if (completedCrop && previewCanvasRef.current && imgRef.current) {
            // cropPreview(
            //     imgRef.current,
            //     previewCanvasRef.current,
            //     completedCrop,
            //     scale,
            //     rotate
            // );
            const canvas = previewCanvasRef.current;
            const image = imgRef.current;
            const crop = completedCrop;
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                throw new Error('No 2d context');
            }

            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            // const pixelRatio = window.devicePixelRatio || 1;

            canvas.width = 320; //Math.floor(crop.width * pixelRatio * scaleX);
            canvas.height = 240; // Math.floor(crop.height * pixelRatio * scaleY);

            // ctx.scale(pixelRatio, pixelRatio);
            ctx.imageSmoothingQuality = 'high';
            // ctx.imageSmoothingEnabled = false;

            const cropX = crop.x * scaleX;
            const cropY = crop.y * scaleY;
            const cropWidth = crop.width * scaleX;
            const cropHeight = crop.height * scaleY;

            // const rotateRads = rotate * TO_RADIANS
            // const centerX = image.width / 2
            // const centerY = image.height / 2

            // ctx.save()
            // ctx.translate(centerX, centerY)
            // ctx.rotate(rotateRads)

            ctx.drawImage(
                image,
                cropX,
                cropY,
                cropWidth,
                cropHeight,
                0,
                0,
                320, //cropWidth,
                240 //cropHeight
            );
        }
    }, [completedCrop]);

    useEffect(() => {
        updateCropPreview();
    }, [updateCropPreview]);

    const [checkmark, setCheckmark] = useState(false);

    const sendImage = useCallback(() => {
        if (!previewCanvasRef.current) {
            return;
        }
        const base64data = previewCanvasRef.current?.toDataURL(
            'image/jpeg',
            1.0
        );
        const send = async () => {
            setIsSending(true);
            const result = await fetchApi({
                path: 'addBoxTask',
                idToken: auth.sessionInfo?.idToken || '',
                method: 'POST',
                params: {
                    task: 'image',
                    boxIds,
                    fileType: 'image/jpeg',
                    base64Image: base64data,
                },
            });

            console.log(result);

            if (!result.error && result.json.ok && result.json.boxes) {
                sharedData.updateData({ boxes: result.json.boxes });
            }

            setIsSending(false);
            setCheckmark(true);
            setTimeout(() => {
                setCheckmark(false);
            }, 2000);
        };

        if (!isSending) {
            send();
        }
    }, [isSending, auth.sessionInfo?.idToken, boxIds, sharedData]);

    return (
        <Grid container direction="column">
            <Box marginTop={3}>
                <Divider />
                <h3>{actionsText || 'Actions:'}</h3>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    onClick={() => sendAction('')}
                    disabled={isSending}
                >
                    Clear
                </Button>{' '}
                <Button
                    variant="contained"
                    onClick={() => sendAction('blink')}
                    disabled={isSending}
                >
                    Blink RGB
                </Button>{' '}
                {isOwnersBox && (
                    <Button
                        variant="contained"
                        onClick={() => sendAction('ota')}
                        disabled={isSending}
                    >
                        Update firmware
                    </Button>
                )}
            </Box>

            <Box marginTop={3}>
                <Divider />
                <h3>{imageActionText || 'Send an image:'}</h3>
            </Box>

            <Box>
                <Grid container marginBottom={3} alignItems="center">
                    <TextField
                        // color="secondary"
                        type="text"
                        name="text"
                        placeholder="Copy paste an image here, or "
                        id="text"
                        style={{ minWidth: '250px' }}
                        onPaste={(e) => {
                            console.log(e.clipboardData.files);
                            const file = e.clipboardData.files?.[0];
                            if (
                                file &&
                                [
                                    'image/png',
                                    'image/jpg',
                                    'image/jpeg',
                                    'image/gif',
                                ].includes(file.type)
                            ) {
                                // if (file.size > 400000) {
                                //     alert('File is too large');
                                // } else {
                                setImageFile(file);
                                // const reader = new FileReader();
                                // reader.onload = (evt) => {
                                //     setImageSrc(evt.target?.result);
                                // };
                                // reader.readAsDataURL(file);
                                // }
                            }
                        }}
                    />{' '}
                    <Box marginLeft={2}>
                        <Button variant="contained" component="label">
                            Upload an image file
                            <input
                                hidden
                                type="file"
                                name="image"
                                id="image"
                                accept="image/*"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        const file = e.target.files[0];
                                        if (
                                            file &&
                                            [
                                                'image/png',
                                                'image/jpg',
                                                'image/jpeg',
                                                'image/gif',
                                            ].includes(file.type)
                                        ) {
                                            setImageFile(file);
                                        }
                                    }
                                }}
                            />
                        </Button>
                    </Box>
                </Grid>

                {imageSrc ? (
                    <>
                        <h3>Crop the image:</h3>
                        <div>
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) =>
                                    setCrop(percentCrop)
                                }
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={4 / 3}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Selected pic here"
                                    src={imageSrc as any}
                                    style={{
                                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                                        maxWidth: '900px',
                                        border: '1px solid green',
                                    }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </div>

                        <h3>Preview and Send</h3>
                        <Grid container direction="row" alignItems="center">
                            <canvas
                                style={{ border: '1px solid green' }}
                                ref={previewCanvasRef}
                            />
                            <Box marginLeft={2}>
                                <Button
                                    variant="contained"
                                    disabled={isSending}
                                    onClick={() => {
                                        sendImage();
                                    }}
                                >
                                    {imageSendText || 'Send'}
                                </Button>{' '}
                                {checkmark ? (
                                    <CheckIcon style={{ color: 'green' }} />
                                ) : null}
                            </Box>
                        </Grid>
                    </>
                ) : null}
            </Box>
        </Grid>
    );
}
